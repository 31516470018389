import React, { useEffect, useState } from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import WindowsLogo from '../../assets/svg/windows.svg';
import AppleLogo from '../../assets/svg/apple.svg';
import LinuxLogo from '../../assets/svg/linux.svg';
import {
  DOWNLOAD_LINUX_ROUTE,
  DOWNLOAD_MAC_OS_INTEL_ROUTE,
  DOWNLOAD_MAC_OS_M_SERIES_ROUTE,
  DOWNLOAD_WINDOWS_ROUTE,
} from '../../const/routes.const';
import Flex from '../common/layout/Flex';
import Box from '../common/layout/Box';
import BetaBadge from '../common/BetaBadge';
import { OS } from '../../const/app.const';
import { detectMobile, detectOs } from '../../utils/os.utils';
import DownloadListItem from './DownloadListItem';
import * as styles from './DownloadList.module.scss';

const DOWNLOADS_LIST = [
  {
    title: <Trans>Windows</Trans>,
    label: <Trans>x64</Trans>,
    link: DOWNLOAD_WINDOWS_ROUTE,
    icon: <WindowsLogo />,
    os: OS.Win,
  },
  {
    title: <Trans>MacOS</Trans>,
    label: <Trans>Intel</Trans>,
    link: DOWNLOAD_MAC_OS_INTEL_ROUTE,
    icon: <AppleLogo />,
    os: OS.MacOSIntel,
  },
  {
    title: <Trans>MacOS</Trans>,
    label: <Trans>M series</Trans>,
    link: DOWNLOAD_MAC_OS_M_SERIES_ROUTE,
    icon: <AppleLogo />,
    os: OS.MacOSM,
  },
  {
    title: (
      <Flex alignItems="center">
        <Trans>Linux</Trans>
        <Box p={1} />
        <BetaBadge />
      </Flex>
    ),
    link: DOWNLOAD_LINUX_ROUTE,
    icon: <LinuxLogo />,
    os: OS.Lin,
  },
];

const DownloadList = (): React.ReactElement => {
  const [clientOs, setClientOs] = useState<OS | undefined>();

  useEffect(() => {
    detectOs().then((data) => {
      if (data && !detectMobile()) {
        setClientOs(data);
      }
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      {DOWNLOADS_LIST.map((download) => (
        <DownloadListItem clientOs={clientOs} key={download.os} {...download} />
      ))}
    </div>
  );
};

export default DownloadList;

// extracted by mini-css-extract-plugin
export var antBtnLg = "DownloadFooter-module--ant-btn-lg--juKLE";
export var antBtnPrimary = "DownloadFooter-module--ant-btn-primary--y+FZ+";
export var antFormItem = "DownloadFooter-module--ant-form-item--W48pu";
export var antFormItemLabel = "DownloadFooter-module--ant-form-item-label--JAs2H";
export var antInput = "DownloadFooter-module--ant-input--v2s-a";
export var antMessageNotice = "DownloadFooter-module--ant-message-notice--VGekg";
export var antMessageNoticeContent = "DownloadFooter-module--ant-message-notice-content--NQNae";
export var errorMessage = "DownloadFooter-module--errorMessage--kHJHv";
export var img = "DownloadFooter-module--img--O-qnP";
export var wrapper = "DownloadFooter-module--wrapper--D3VFe";
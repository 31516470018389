const headers = { 'Content-Type': 'application/json' };

export const GET_JSON_RESPONSE_DEFAULT_FORMAT = {
  method: 'GET',
  headers,
};

export const POST_JSON_RESPONSE_DEFAULT_FORMAT = {
  method: 'POST',
  headers,
};

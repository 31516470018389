// extracted by mini-css-extract-plugin
export var antBtnLg = "DownloadFeaturesListItem-module--ant-btn-lg--9gkqt";
export var antBtnPrimary = "DownloadFeaturesListItem-module--ant-btn-primary--pL+N1";
export var antFormItem = "DownloadFeaturesListItem-module--ant-form-item--jN2OJ";
export var antFormItemLabel = "DownloadFeaturesListItem-module--ant-form-item-label--+nSnJ";
export var antInput = "DownloadFeaturesListItem-module--ant-input--ep6BH";
export var antMessageNotice = "DownloadFeaturesListItem-module--ant-message-notice--FWDN3";
export var antMessageNoticeContent = "DownloadFeaturesListItem-module--ant-message-notice-content--p+BTO";
export var errorMessage = "DownloadFeaturesListItem-module--errorMessage--c45Av";
export var icon = "DownloadFeaturesListItem-module--icon--DiH9G";
export var wrapper = "DownloadFeaturesListItem-module--wrapper--FjFMh";
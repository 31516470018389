import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import DownloadFeaturesListItem from './DownloadFeaturesListItem';
import * as styles from './DownloadFeaturesList.module.scss';

const FEATURES_LIST = [
  {
    key: 'feature1',
    text: (
      <Trans>
        Scale your business and create thousands of unique profiles with real fingerprints
      </Trans>
    ),
  },
  {
    key: 'feature2',
    text: <Trans>Automate profile actions using API and browser automation frameworks</Trans>,
  },
  {
    key: 'feature3',
    text: <Trans>Share profiles and manage roles in teams to secure your data</Trans>,
  },
];

const DownloadFeaturesList = (): React.ReactElement => (
  <div className={styles.wrapper}>
    {FEATURES_LIST.map((feature) => (
      <DownloadFeaturesListItem key={feature.key} text={feature.text} />
    ))}
  </div>
);

export default DownloadFeaturesList;

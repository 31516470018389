import { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { ImageComponentType } from '../types/image.types';

export const getImagesByLocale = (
  images: ImageComponentType[],
  locale: string
): ImageComponentType[] =>
  images.filter((img) => {
    if (img?.locales?.include) {
      return img.locales.include.includes(locale);
    } else if (img?.locales?.exclude) {
      return !img.locales.exclude.includes(locale);
    }

    return img;
  });

export default function useImagesByLocale(
  images: ImageComponentType[]
): ImageComponentType[] {
  const { language } = useI18next();

  const [localeImages, setLocaleImages] = useState(
    getImagesByLocale(images, language)
  );

  useEffect(
    () => setLocaleImages(getImagesByLocale(images, language)),
    [language]
  );

  return localeImages;
}

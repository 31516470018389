import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import ContentWrap from '../ContentWrap/ContentWrap';
import DownloadAgreement from '../DownloadAgreement/DownloadAgreement';
import * as styles from './Download.module.scss';
import DownloadList from './DownloadList';
import DownloadFeaturesList from './DownloadFeaturesList';
import DownloadFooter from './DownloadFooter';

const Download = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <ContentWrap>
      <h1 className={styles.title}>
        <Trans>Download Octo Browser and start working right now!</Trans>
      </h1>
      <DownloadFeaturesList />
      <DownloadList />
      <DownloadAgreement />
    </ContentWrap>
    <DownloadFooter />
  </div>
);

export default Download;

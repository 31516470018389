import React from 'react';
import { Link, Trans } from 'gatsby-plugin-react-i18next';
import {
  EULA_ROUTE,
  PRIVACY_POLICY_ROUTE,
  TERMS_ROUTE,
} from '../../const/routes.const';
import * as styles from './DownloadAgreement.module.scss';

const DownloadAgreement = (): React.ReactElement => (
  <div className={styles.wrapper}>
    <Trans i18nKey="download.agreement">
      <span>By downloading Octo Browser, you accept </span>
      <Link to={TERMS_ROUTE}>Terms of Use</Link>
      <span>, </span>
      <Link to={EULA_ROUTE}>User Agreement</Link>
      <span>, </span>
      <Link to={PRIVACY_POLICY_ROUTE}>Privacy Policy</Link>
    </Trans>
  </div>
);

export default DownloadAgreement;

import React from 'react';
import Check from '../../assets/svg/check.svg';
import * as styles from './DownloadFeaturesListItem.module.scss';

type DownloadFeaturesListItemProps = {
  text: React.ReactNode;
};
const DownloadFeaturesListItem = ({ text }: DownloadFeaturesListItemProps): React.ReactElement => (
  <div className={styles.wrapper}>
    <div className={styles.icon}>
      <Check />
    </div>
    <div>{text}</div>
  </div>
);

export default DownloadFeaturesListItem;

import { OS } from '../const/app.const';
import {
  INSTALLATION_LIN_ROUTE,
  INSTALLATION_MAC_INTEL_ROUTE,
  INSTALLATION_MAC_M_SERIES_ROUTE,
  INSTALLATION_WIN_ROUTE,
} from '../const/routes.const';

export const detectMobile = (): boolean => /Android|iPhone/i.test(navigator.userAgent);

export const detectOs = async (): Promise<OS | undefined> => {
  let os;

  switch (true) {
    case navigator.userAgent.indexOf('Win') !== -1:
      os = OS.Win;
      break;

    case navigator.userAgent.indexOf('Mac') !== -1:
      try {
        const uaData = await navigator?.userAgentData?.getHighEntropyValues(['architecture']);

        if (uaData) {
          if (uaData.architecture === 'arm') {
            os = OS.MacOSM;
          } else {
            os = OS.MacOSIntel;
          }
        }
      } catch (e) {}
      break;

    case navigator.userAgent.indexOf('Linux') !== -1:
      os = OS.Lin;
      break;

    default: {
      break;
    }
  }

  return os;
};

export const getOsInstructionPath = (os: OS): string => {
  switch (os) {
    case OS.Win:
      return INSTALLATION_WIN_ROUTE;

    case OS.Lin:
      return INSTALLATION_LIN_ROUTE;

    case OS.MacOSM:
      return INSTALLATION_MAC_M_SERIES_ROUTE;

    case OS.MacOSIntel:
      return INSTALLATION_MAC_INTEL_ROUTE;

    default:
      return '';
  }
};

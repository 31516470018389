// extracted by mini-css-extract-plugin
export var antBtnLg = "DownloadListItem-module--ant-btn-lg--0YpG1";
export var antBtnPrimary = "DownloadListItem-module--ant-btn-primary--yhxjJ";
export var antFormItem = "DownloadListItem-module--ant-form-item---G-it";
export var antFormItemLabel = "DownloadListItem-module--ant-form-item-label--s9Ewj";
export var antInput = "DownloadListItem-module--ant-input--XDBXl";
export var antMessageNotice = "DownloadListItem-module--ant-message-notice--QUjLj";
export var antMessageNoticeContent = "DownloadListItem-module--ant-message-notice-content--tU5ft";
export var button = "DownloadListItem-module--button--jQXLY";
export var errorMessage = "DownloadListItem-module--errorMessage--thL2b";
export var icon = "DownloadListItem-module--icon--sW6bM";
export var label = "DownloadListItem-module--label--hUx9b";
export var selected = "DownloadListItem-module--selected--4hMjU";
export var title = "DownloadListItem-module--title--WLDcM";
export var wrapper = "DownloadListItem-module--wrapper--bjwaG";
import React, { useEffect } from 'react';
import { graphql, PageProps, navigate } from 'gatsby';
import { getObjectFromQueryString } from '../../utils/objectUtils';
import { getUserMetaDataFromStorage } from '../../utils/analytics.utils';
import { INJECT_TRACKING } from '../../const/api.const';
import { POST_JSON_RESPONSE_DEFAULT_FORMAT } from '../../const/requests.const';
import { ResponseType } from '../../types/common.types';
import Layout from '../../layouts';
import Download from '../../components/Download/Download';
import AppRating from '../../components/AppRating';

const DownloadPage = (props: PageProps): React.ReactElement => {
  const { location } = props;
  const { search, pathname } = location;
  const queryObject = getObjectFromQueryString(search);

  useEffect(() => {
     if (queryObject.user_uuid) {
       fetch(`${INJECT_TRACKING}/${queryObject.user_uuid}`, {
         ...POST_JSON_RESPONSE_DEFAULT_FORMAT,
         body: JSON.stringify({
           ...getUserMetaDataFromStorage(),
           cid: queryObject.user_uuid,
         })
       })
         .then((response) => response.json())
         .then((data: ResponseType<unknown>) => {
           if (data.success) {
             navigate(pathname);
           }
         });
     }
  }, []);

  return (
    <Layout>
      <AppRating />
      <Download />
    </Layout>
  )
};

export default DownloadPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["translation", "downloadPage"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// extracted by mini-css-extract-plugin
export var antBtnLg = "Download-module--ant-btn-lg--tWpKz";
export var antBtnPrimary = "Download-module--ant-btn-primary---Ephs";
export var antFormItem = "Download-module--ant-form-item--A7WNS";
export var antFormItemLabel = "Download-module--ant-form-item-label--+W6pi";
export var antInput = "Download-module--ant-input--s46Mv";
export var antMessageNotice = "Download-module--ant-message-notice--U2jYf";
export var antMessageNoticeContent = "Download-module--ant-message-notice-content--IIgd4";
export var errorMessage = "Download-module--errorMessage--naVMg";
export var title = "Download-module--title--z9Wsy";
export var wrapper = "Download-module--wrapper--G0G8m";
import React from 'react';
import bgRuSrc from '../../assets/images/download/download_footer_ru.png';
import bgRuSrc2x from '../../assets/images/download/download_footer_ru_2x.png';
import bgEnSrc from '../../assets/images/download/download_footer_en.png';
import bgEnSrc2x from '../../assets/images/download/download_footer_en_2x.png';
import ImageContainer from '../ImageContainer/ImageContainer';
import directionBwinSrc from '../../assets/images/directions/bwin.png';
import directionBwinSrc2x from '../../assets/images/directions/bwin_2x.png';
import { LanguageMap } from '../../const/language.const';
import useImagesByLocale from '../../hooks/useImagesByLocale.hook';
import ContentWrap from '../ContentWrap/ContentWrap';
import * as styles from './DownloadFooter.module.scss';

const IMAGES_BG = [
  {
    image: <ImageContainer src={bgRuSrc} srcSet={{ _2x: bgRuSrc2x }} />,
    locales: {
      include: [LanguageMap.ru.key],
    },
  },
  {
    image: <ImageContainer src={bgEnSrc} srcSet={{ _2x: bgEnSrc2x }} />,
    locales: {
      include: [LanguageMap.en.key, LanguageMap.pt.key],
    },
  },
];

const DownloadFooter = (): React.ReactElement | null => {
  const img = useImagesByLocale(IMAGES_BG);

  if (!img[0]) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <ContentWrap>
        <div className={styles.img}>{img[0].image}</div>
      </ContentWrap>
    </div>
  );
};

export default DownloadFooter;

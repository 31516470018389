import React from 'react';
import { navigate } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import Button from '../Button/Button';
import { ButtonSize } from '../../const/button.conts';
import { OS } from '../../const/app.const';
import { getOsInstructionPath } from '../../utils/os.utils';
import * as styles from './DownloadListItem.module.scss';

type DownloadListItemProps = {
  title: string | React.ReactNode;
  link: string;
  icon: React.ReactNode;
  os: OS;
  clientOs?: OS;
  label?: string | React.ReactNode;
};
const DownloadListItem = ({
  label,
  link,
  icon,
  title,
  os,
  clientOs,
}: DownloadListItemProps): React.ReactElement => {
  const { defaultLanguage, language } = useI18next();

  const handleDownload = () => {
    setTimeout(
      () =>
        navigate(
          `${defaultLanguage !== language ? `/${language}` : ''}${getOsInstructionPath(os)}`
        ),
      500
    );
  };

  return (
    <div className={classNames(styles.wrapper, clientOs === os && styles.selected)}>
      <div>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.title}>{title}</div>
        {label && <div className={styles.label}>{label}</div>}
      </div>
      <div className={styles.button}>
        <Button
          externalLink
          label={<Trans>Download</Trans>}
          link={link}
          size={ButtonSize.small}
          onClick={handleDownload}
        />
      </div>
    </div>
  );
};

export default DownloadListItem;
